import React from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { keyframes } from '@emotion/react'

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(147, 112, 219, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(147, 112, 219, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(147, 112, 219, 0);
  }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export default function LoadingSpinner({ loading_text }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: '#121212',
      }}
    >
      <div style={{position: 'relative', width: 200, height: 200}}> 
        <CircularProgress
          size={200}
          thickness={2}
          sx={{
            color: 'secondary.main', // Medium Purple
            position: 'absolute',
            left: 0,
            animation: `${rotate} 10s linear infinite`,
          }}
        />
        <CircularProgress
          size={160}
          thickness={4}
          sx={{
            color: 'rgba(206, 147, 216, 0.7)', // Medium Purple with opacity
            position: 'absolute',
            left: 20,
            top: 20,
            animation: `${rotate} 5s linear infinite reverse`,
          }}
        />
        <CircularProgress
          size={120}
          thickness={6}
          sx={{
            color: 'rgba(206, 147, 216, 0.5)', // Medium Purple with more opacity
            position: 'absolute',
            left: 40,
            top: 40,
            animation: `${rotate} 3s linear infinite`,
          }}
        />
      </div>
      <Typography
        variant="h6"
        sx={{
          color: '#E0E0E0', // Light grey for better readability in dark mode
          mt: 4,
          fontWeight: 'bold',
          textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
        }}
      >
        {loading_text}
      </Typography>
    </Box>
  )
}