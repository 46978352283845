import {React, useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate } from 'react-router-dom';

import ShareableLink from './pages/shareable_link';
import Layout from './pages/layout';
import Header from './components/header';
import Footer from './components/footer';
import SelfDestructingMessage from './pages/self_destructing_message';
import SelfDestructingFile from './pages/self_destructing_file';
import Maintenance from './pages/maintrance_page';

import { retrieveMessage, isMaintenance, retrieveFile } from './utils/backend_api';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from './components/loading';


const convertByteBuffertoBase64 = async (byteBuffer) => {
  const bytes = new Uint8Array(byteBuffer);
  let binary = '';
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += await String.fromCharCode(bytes[i]);
  }
  return await window.btoa(binary);
};

const SelfDestructingMessageWrapper = () => {
  const [messageContent, setMessageContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [backendWorks, setbackendWorks] = useState(false);
  const random_string = useParams();

  useEffect(() => {
    const fetchMessagePromise = new Promise(async (resolve, reject) => {
      try {
        const response = await retrieveMessage(random_string.random_string);

        if(response === "ERR_NETWORK" || response === "ECONNREFUSED"){
          setbackendWorks(false);
          reject(new Error('Backend is down!'));
        }
        else if (response) {
          setMessageContent(response);
          setbackendWorks(true);
          resolve('Message retrieved successfully!');
        } else {
          setbackendWorks(true);
          reject(new Error('Message not found!'));
        }

      } catch (error) {
        reject(new Error('Unkown Error, Contact a developer!'));
        setbackendWorks(false);
      } finally {
        //setbackendWorks(true);
        setIsLoading(false);
      }
    });

    toast.promise(
      fetchMessagePromise,
      {
        pending: 'Fetching message...',
        success: 'Message retrieved successfully!',
        error: {
          render({ data }) {
            return data.message;
          }
        }
      }
    );

    fetchMessagePromise.catch((error) => console.error('Error fetching message:', error));
  }, [random_string]);

  if(backendWorks){
    if (!messageContent && !isLoading) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ color: 'red' }}>Message was not found!</h1>
          <h2>Contact the sender of this message directly to send this message again.</h2>
          {/* {window.location.href = '/'} */}
        </div>
      );
    }
    else if(messageContent && !isLoading){
      return <SelfDestructingMessage message={messageContent} id={random_string.random_string} />;
    }
  }
  else {
    if(isLoading){
      return (
        <LoadingSpinner loading_text={'Loading..'}></LoadingSpinner>
      );
    }
    else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ color: 'red' }}>Backend Error!</h1>
          <h2>Contact the site owner to resolve this issue.</h2>
        </div>
      );
    }
  }
};

const SelfDestructingFileWrapper = () => {
  const [FileContent, setFileContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [FileExtention, setFileExtention] = useState('');
  const [backendWorks, setbackendWorks] = useState(false);
  const random_string = useParams();

  useEffect(() => {
    const fetchFilePromise = new Promise(async (resolve, reject) => {
      try {
        const response = await retrieveFile(random_string.random_string);

        if(response === "ERR_NETWORK" || response === "ECONNREFUSED") {
          setbackendWorks(false);
          reject(new Error('Backend is down!'));
        }
        else if (response === "C_ERR_NOT_FOUND"){
          setbackendWorks(true);
          reject(new Error('File was not found!'));
        }
        else if (response) {
          setbackendWorks(true);
          // const conent = await convertByteBuffertoBase64(response.file.data);
          setFileContent(response.file);
          setFileExtention(response.file_extention);
          resolve('File retrieved successfully!');
        } else {
          setbackendWorks(true);
          reject(new Error('File not found!'));
        }
      } catch (error) {
        reject(new Error('Unkown Error, Contact a developer!'));
        setbackendWorks(false);
      } finally {
        //setbackendWorks(true);
        setIsLoading(false);
      }
    });

    toast.promise(
      fetchFilePromise,
      {
        pending: 'Fetching File...',
        success: 'File retrieved successfully!',
        error: {
          render({ data }) {
            return data.message;
          }
        }
      }
    );

    fetchFilePromise.catch((error) => console.error('Error fetching file:', error));
  }, [random_string]);

  if(backendWorks){
    if (!FileContent && !isLoading) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ color: 'red' }}>File was not found!</h1>
          <h2>Contact the sender of this File directly to send this File again.</h2>
          {/* {window.location.href = '/'} */}
        </div>
      );
    }
    else if(FileContent && !isLoading){
      return <SelfDestructingFile file={FileContent} file_extention={FileExtention} id={random_string.random_string} />;
    }
  }
  else {
    if(isLoading){
      return (
        <LoadingSpinner loading_text={'Loading..'}></LoadingSpinner>
      );
    }
    else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ color: 'red' }}>Backend Error!</h1>
          <h2>Contact the site owner to resolve this issue.</h2>
        </div>
      );
    }
  }
};


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


const App = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(null);

  if(isMaintenanceMode === null){
    const checkMaintenancePromise = new Promise(async (resolve, reject) => {
      try {
        const maintenanceMode = await isMaintenance();
        if(maintenanceMode === "ERR_NETWORK" || maintenanceMode === "ECONNREFUSED"){
          setIsMaintenanceMode(maintenanceMode);
          reject(new Error('Service is down!'));
        }
        else if(!maintenanceMode){
          setIsMaintenanceMode(false);
          resolve();
        }
        else if(maintenanceMode){
          setIsMaintenanceMode(true);
          reject(new Error('Service is under maintenance!'));
        }
      } catch (error) {
        reject(new Error('Unknown Service Error!'));
      }
    });

    toast.promise(
      checkMaintenancePromise,
      {
        // pending: 'Connecting to Service...',
        // success: 'privy.tools Loaded!!',
        error: {
          render({ data }) {
            return data.message;
          }
        }
      }
      ,{autoClose: 1000}
    );
}

  if(isMaintenanceMode === false){
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Router>
          <Header />
          <Layout>
            <Routes>
              <Route path="/" element={<ShareableLink />} />
              <Route path="/message/:random_string" element={<SelfDestructingMessageWrapper />} />
              <Route path="/file/:random_string" element={<SelfDestructingFileWrapper />} />
            </Routes>
          </Layout>
          <ToastContainer theme="dark" />
          <Footer />
        </Router>
        </ThemeProvider>
      );
  }
  else if(isMaintenanceMode === null){
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Header />
        <Layout>
          <LoadingSpinner loading_text={'Connecting to Service...'}></LoadingSpinner>
        </Layout>
        <Footer />
      </ThemeProvider>
    );
  }
  else if (isMaintenanceMode === "ERR_NETWORK" || isMaintenanceMode === "ECONNREFUSED") {
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Header />
        <Layout>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 style={{ color: 'red' }}>Service is down!</h1>
            <h2>Please try again or contact the Developer.</h2>
          </div>
        </Layout>
        <ToastContainer theme="dark" />
        <Footer />
      </ThemeProvider>
    );
  }
  else if(isMaintenanceMode === true){
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Header />
        <Layout>
          <Maintenance/>
        </Layout>
        <ToastContainer theme="dark" />
        <Footer />
      </ThemeProvider>
    );
  }
};

export default App;