import axios from 'axios';

const run_local = false;
const BASE_URL = run_local ? 'http://localhost:2000' : 'https://api.privy.tools';

export const createMessage = async (id, content) => {
  //console.log('Creating message:', id, content);
  try {
    const response = await axios.post(BASE_URL + "/messages", { id, content });

    if(response.status === 201){
      return true;
    }
    else if(response.status === 500){//Internal Server Error
      return false;
    }
  } catch (error) {
    if(error.code === "ERR_NETWORK"){//Backend is down
      return "ERR_NETWORK"
    }
    else if(error.code === "ECONNREFUSED"){//Backend refused connection
      return "ECONNREFUSED"
    }
    return false;
  }
};

export const retrieveMessage = async (id) => {
  //console.log('Retrieving message:', id);
  try {
    const response = await axios.get(`${BASE_URL + "/messages"}/${id}`);
    
    if(response.status === 200){
      return response.data;
    }
    else if(response.satus === 500){//Internal Server Error
      return false;
    }
        // else if(response.status === 404){//Message not found
        //   return false;
        // }
  } catch (error) {
    if(error.code === "ERR_NETWORK"){//Backend is down
      return "ERR_NETWORK"
    }
    else if(error.code === "ECONNREFUSED"){//Backend refused connection
      return "ECONNREFUSED"
    }
    return false;
  }
};

export const deleteMessage = async (id) => {
  //console.log('Deleting message:', id);
  try {
    const response = await axios.delete(`${BASE_URL + "/messages"}/${id}`);
    if(response.status === 204){//Sucess
      return true;
    }
    else if(response.satus === 500){//Internal Server Error
      return false;
    }
    else if(response.status === 404){//Message not found
      return "C_ERR_NOT_FOUND";
    }
  } catch (error) {
    if(error.code === "ERR_NETWORK"){//Backend is down
      return "ERR_NETWORK"
    }
    else if(error.code === "ECONNREFUSED"){//Backend refused connection
      return "ECONNREFUSED"
    }
    return false;
  }
};

export const createFile = async (id, file, file_extention) => {
  try {
    // console.log('Creating file:', id, file, file_extention);
    
    const formData = new FormData();
    formData.append('id', id);
    formData.append('file', file);
    formData.append('file_extention', file_extention);

    const response = await axios.post(BASE_URL + "/files", formData, {
      headers: {
      'Content-Type': 'multipart/form-data'
      }
    });

    if(response.status === 201){
      return true;
    }
    else if(response.status === 500){//Internal Server Error
      return false;
    }
  }
  catch (error) {
    if(error.code === "ERR_NETWORK"){//Backend is down
      return "ERR_NETWORK"
    }
    else if(error.code === "ECONNREFUSED"){//Backend refused connection
      return "ECONNREFUSED"
    }
    return false;
  }
};

export const retrieveFile = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL + "/files"}/${id}`);
    if(response.status === 200){
      return (response.data);
    }
    else if(response.status === 500){//Internal Server Error
      return false;
    }
    else if(response.status === 404){//File not found
      return false;
    }
    else if(response.data === "File not found"){//File not found
      return false;
    }
  } catch (error) {
    if(error.code === "ERR_NETWORK"){//Backend is down
      return "ERR_NETWORK"
    }
    else if(error.code === "ECONNREFUSED"){//Backend refused connection
      return "ECONNREFUSED"
    }
    else if(error.response.data === "File not found"){//File not found
      return "C_ERR_NOT_FOUND";
    }
    return false;
  }
}

export const deleteFile = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL + "/files"}/${id}`);
    console.log('response:', response);
    if(response.status === 204){//Sucess
      return "FILE_DELETED";
    }
    else if(response.status === 500){//Internal Server Error
      return "ERR_INTERNAL_SERVER_ERROR";
    }
    else if(response.status === 404){//File not found
      return "C_ERR_NOT_FOUND";
    }
  } catch (error) {
    if(error.code === "ERR_NETWORK"){//Backend is down
      return "ERR_NETWORK"
    }
    else if(error.code === "ECONNREFUSED"){//Backend refused connection
      return "ECONNREFUSED"
    }
    else if(error.code === "ERR_BAD_REQUEST") {
      return "ERR_BAD_REQUEST";
    }
    else if(error.response.data === "File not found"){//File not found
      return "C_ERR_NOT_FOUND";
    }
    return false;
  }
};

export const isMaintenance = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/maintrance`);
    if(response.status === 200){
      return false;
    }
    else if(response.status === 503){
      return true;
    }
  } catch (error) {
    if(error.code === "ERR_NETWORK"){//Backend is down
      return "ERR_NETWORK"
    }
    else if(error.code === "ECONNREFUSED"){//Backend refused connection
      return "ECONNREFUSED"
    }
    return true;
  }
};