import React, { useState } from 'react';
import { createMessage, createFile } from '../utils/backend_api';
import { generate_random_string } from '../utils/util';
import { TextField, Button, Typography, withTheme, IconButton, Divider } from '@mui/material';
import { TextSnippet, UploadFile  } from '@mui/icons-material';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import LoadingSpinner from '../components/loading';
import 'react-toastify/dist/ReactToastify.css';
import { Switch } from '@mui/material';

function is_mobile_device() {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('Mobile') !== -1);
}

const ShareableLink = () => {
  const [message, set_message] = useState('');
  const [link, set_link] = useState('');
  const [user_done_input, set_user_done_input] = useState(false);
  const [user_generated_link, set_user_generated_link] = useState(false);
  const [uploadMode, setUploadMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const generateRandomKey = async () => {
    return await CryptoJS.lib.WordArray.random(16).toString();
  };

  const encryptMessage = async (message, key) => {
    return await CryptoJS.AES.encrypt(message, key).toString();
  };

  const encryptFile = async (file, key) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        const file_data = reader.result;
        const encryptedData = await CryptoJS.AES.encrypt(file_data, key).toString();
        resolve(encryptedData);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const message_failed_to_create = () => {
    return (
      <div>
        <Typography variant="h4" style={{ color: "red" }}>Failed to create message, please contact the site owner!</Typography>
      </div>
    );
  };

  const handle_generate_link = async () => {
    setLoading(true);
    const random_string = await generate_random_string();
    const generated_key = await generateRandomKey();

    // console.log('random_string: ', random_string);
    // console.log('generated_key: ', generated_key);
    
    const encrypted_message = await encryptMessage(message, generated_key);
    
    // console.log('message: ', message);
    // console.log('encrypted_message: ', encrypted_message);

    const createMessagePromise = new Promise(async (resolve, reject) => {
      try {
        const response = await createMessage(random_string, encrypted_message);
        if (response === "ERR_NETWORK" || response === "ECONNREFUSED") {
          reject(new Error('Failed to create message, Backend is down!'));
        } else if (!response) {
          reject('Failed to create message!');
        } else {
          const generated_link = `${window.location.origin}/message/${random_string}?key=${generated_key}`;
          set_link(generated_link);
          resolve('Message created successfully!');
        }
      } catch (error) {
        reject('Failed to create message!');
      }
    });

    toast.promise(
      createMessagePromise,
      {
        pending: 'Creating message...',
        success: 'Message created successfully!',
        error: {
          render({ data }) {
            return data.message;
          }
        }
      }
    );

    createMessagePromise.catch(() => message_failed_to_create()).finally(() => setLoading(false));
  };

  const handle_generate_link_file = async (file) => {
    setLoading(true);
    const file_extention = file.name.split('.').pop();
    const file_id = await generate_random_string();
    const generated_key = await generateRandomKey();
    const encrypted_file = await encryptFile(file, generated_key);

    const createFilePromise = new Promise(async (resolve, reject) => {
      try {
        const response = await createFile(file_id, encrypted_file, file_extention);
        if (response === "ERR_NETWORK" || response === "ECONNREFUSED") {
          reject(new Error('Failed to create message, Backend is down!'));
        } else if (!response) {
          reject('Failed to create message!');
        } else {
          const generated_link = `${window.location.origin}/file/${file_id}?key=${generated_key}`;
          set_link(generated_link);
          resolve('Message created successfully!');
        }
      } catch (error) {
        reject('Failed to create message!');
      }
    });

    toast.promise(
      createFilePromise,
      {
        pending: 'Creating message...',
        success: 'Message created successfully!',
        error: {
          render({ data }) {
            return data.message;
          }
        }
      }
    );

    createFilePromise.catch(() => message_failed_to_create()).finally(() => setLoading(false));
  };

  const renderInputField = () => (
    <TextField
      multiline
      label="Enter your message"
      color='secondary'
      autoComplete='off'
      variant="outlined"
      error={user_done_input && message.length === 0}
      helperText={(message.length === 0 && user_done_input) ? 'Message cannot be empty' : ''}
      autoFocus={true}
      value={message}
      onChange={(e) => {
        set_message(e.target.value);
        set_user_done_input(true);
      }}
      style={{ margin: '10px', width: '300px' }}
    />
  );

  const renderFileUploadButton = () => (
    <Button
      variant="contained"
      color="secondary"
      component="label"
      style={{ margin: '10px' }}
      disabled={user_generated_link}
    >
      Select File
      <input
        style={{ display: 'none' }}
        type="file"
        onChange={async (e) => {
          if (e.target.files.length === 0) return;
          const file = e.target.files[0];
          const fileSize = file.size / (1024 * 1024);
          if (fileSize > 250) {//mb
            toast.error('File size exceeds the limit of 500MB');
            return;
          } else {
            set_user_done_input(true);
            set_user_generated_link(true);
            await handle_generate_link_file(file);
          }
        }}
      />
    </Button>
  );

  const renderLinkField = () => (
    link && (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        {!is_mobile_device() && (
          <Divider orientation='horizontal' color='white' style={{margin: '20px'}} flexItem></Divider>
        )}        
        <TextField
          label="Shareable Link"
          color='secondary'
          variant="standard"
          value={link}
          InputProps={{ readOnly: true }}
          style={{ margin: '10px', width: '90%' }}
        />
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', margin: '10px' }}>
          <Button
            variant="outlined"
            color="info"
            onClick={() => {
              navigator.clipboard.writeText(link);
              toast.success('Link copied to clipboard');
            }}
          >
            Copy
          </Button>

          <Button
            variant='outlined'
            color='info'
            onClick={() => {
              navigator.share({
                title: 'privy.tools Message:',
                text: 'Message from privy.tools',
                url: link
              });
            }}
          >
            Share
          </Button>
        </div>
      </div>
    )
  );

  const renderContent = () => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {loading && <LoadingSpinner loading_text={'Uploading File'}/>}
      <IconButton
        color="secondary"
        style={{ margin: '10px' }}
        onClick={() => {
          setUploadMode(!uploadMode);
          set_link('');
          set_user_generated_link(false);
        }}
      >
        {!uploadMode ? <UploadFile /> : <TextSnippet />}
      </IconButton>
      {!uploadMode ? (
        <>
          {renderInputField()}
          <text style={{ color: 'grey' }}>Unread messages are automatically deleted after 3 days and cannot be recovered.</text>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handle_generate_link();
              set_user_generated_link(true);
            }}
            disabled={message.length === 0 || user_generated_link}
            style={{ margin: '10px' }}
          >
            Generate Link
          </Button>
        </>
      ) : (
        <>
        <text style={{ color: 'grey' }}>Unread files are automatically deleted after 3 days and cannot be recovered.</text>
        <text style={{ color: 'red' }}>Please be advised that the filename will be lost due to privacy reasons.</text>
        {renderFileUploadButton()}
        </>
      )}
      {renderLinkField()}
    </div>
  );

  return renderContent();
};

export default ShareableLink;