import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import { createMessage, retrieveMessage, deleteMessage } from '../utils/backend_api';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HandleReturn = () => {
  window.location.href = '/';
};

function is_mobile_device() {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('Mobile') !== -1);
}

const SelfDestructingMessage = ({ message, id }) => {
  const [MessageOpen, SetMessageOpen] = useState(false);
  const [DeletedDisplayed, SetDeletedDisplayed] = useState(false);
  
  const decrypt_message = (encryptedMessage, key) => {
    const bytes = CryptoJS.AES.decrypt(encryptedMessage, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

    
  const DisplayMessagePage = ({ message, id }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('key');
    const message_chached = decrypt_message(message, key);
    const [backendWorks, setbackendWorks] = useState(false);
  
    useEffect(() => {
      const deleteMessagePromise = new Promise(async (resolve, reject) => {
        try {
          const response = await deleteMessage(id);
          if (response === "ERR_NETWORK" || response === "ECONNREFUSED") {
            setbackendWorks(false);
            reject(new Error('Failed to destroy Message, Backend is down!'));
          }
          else if(response === "C_ERR_NOT_FOUND"){
            setbackendWorks(true);
            resolve('Message was allready deleted!')
            return;
          }
          else if (response) {
            setbackendWorks(true);
            resolve('Message deleted from Server!');
          } 
          else if(!response){
            setbackendWorks(true);
            // console.log('Failed to destroy Message!');
            resolve('code: 500');
            //reject(new Error('Failed to destroy Message!'));
          }
        } catch (error) {
          reject(new Error('Unknown Error deleting message!'));
        }
      });
    
        toast.promise(
          deleteMessagePromise,
          {
            pending: 'Deleting message...',
            success: 'Message deleted from Server!',
            error: {
              render({ data }) {
                return data.message;
              }
            }
          }
        );
    }, [id]);

    if (!message_chached) {
      return (
        <div>
          <Typography variant="h4" style={{ color: 'red' }}>Failed to destroy Message, please contact the site owner!</Typography>
        </div>
      );
    }

    return (
      <div>
        <TextField label="Message" color='secondary' variant="standard" value={message_chached} InputProps={{ readOnly: true, multiline: true }} style={{ margin: '10px', width: is_mobile_device() ? '100%' : '800px' }} />
        <Button variant="outlined" color={"secondary"} onClick={HandleReturn} style={{ top: "15px" }}>Return to Home</Button>
      </div>
    );
  };
  
  const DisplayViewNotificationPage = () => {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleYes = () => {
      SetMessageOpen(true);
      handleClose();
    };
  
    const handleNo = () => {
      navigate('/');
      handleClose();
    };

    if(!open){
      navigate('/');
    }

      return (
        <div>
          <Dialog open={handleOpen} onClose={handleClose}>
            <DialogTitle>Do you want to open this Message?</DialogTitle>
            <DialogContent style={{color: "yellow", fontSize: "30px"}}>
              After viewing this message, it will be permanently destroyed.
            </DialogContent>
            <DialogActions>
              <Button onClick={handleYes} color="success">
                Yes
              </Button>
              <Button onClick={handleNo} color="error" autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
  };

  return(
    <div>
    {MessageOpen ? 
        <DisplayMessagePage message={message} id={id}></DisplayMessagePage> 
        : //OR
        <DisplayViewNotificationPage></DisplayViewNotificationPage>
      }
    </div>


  );
};

export default SelfDestructingMessage;