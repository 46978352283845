import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography,Card, CardContent, IconButton  } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { InsertDriveFile, Download } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { createFile, retrieveFile, deleteFile } from '../utils/backend_api';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import LoadingSpinner from '../components/loading';
import 'react-toastify/dist/ReactToastify.css';

const HandleReturn = () => {
  window.location.href = '/';
};

function is_mobile_device() {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('Mobile') !== -1);
}

const decrypt_file = async (file, key) => {
  const bytes = CryptoJS.AES.decrypt(file, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const SelfDestructingFile = ({ file, file_extention, id }) => {
  const [FileOpen, SetFileOpen] = useState(false);
  const [DecryptedFile, SetDecryptedFile] = useState('');
  const [DeletedDisplayed, SetDeletedDisplayed] = useState(false);
  const [Loading, SetLoading] = useState(false);	
  
  const navigate = useNavigate();

  const generate_file_size_text = (file_size) => {
    if (file_size>= 1000000) {
      return `${(file_size / 1000000).toFixed(2)} MB`;
    }
    if (file_size >= 1000) {
      return `${(file_size / 1000).toFixed(2)} KB`;
    }
    return `${file_size} Bytes`;
  };

  const DisplayFileDownloadPage = ({ encrypted_file, _file_extention, _id }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('key');
    const o_encrypted_content = encrypted_file[0];
    const o_file_extention = _file_extention[0];

    
    const [userDownloaded, setUserDownloaded] = useState(false);
    const [fileDetetedFromServer, setFileDetetedFromServer] = useState(false);

    const download_base64_url_file = () => {
      const base64Data = DecryptedFile.split(',')[1]; // remove the `data:application/octet-stream;base64,` part
      const binaryData = atob(base64Data);
      const byteArray = new Uint8Array(binaryData.length);
  
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }
  
      const blob = new Blob([byteArray], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = `${_id}.${o_file_extention}`;
      document.body.appendChild(link);
      link.click();
  
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    };

    useEffect(() => {
      const fetchDecryptedFile = async () => {
        const decrypted_file = await decrypt_file(o_encrypted_content, key);
        SetDecryptedFile(decrypted_file);
      };
      
      if (DecryptedFile === '') {
        fetchDecryptedFile();
      }
    }, [o_encrypted_content, key]);

    useEffect(() => {
      const fetchDecryptedFile = async () => {
        const decrypted_file = await decrypt_file(o_encrypted_content, key);
        SetDecryptedFile(decrypted_file);
      };
      
      fetchDecryptedFile();
    }, [o_encrypted_content, key]);
    
    useEffect(() => {
      if (!fileDetetedFromServer) {
        const deleteFilePromise = new Promise(async (resolve, reject) => {
          const response = await deleteFile(_id);
          if (response === "ERR_NETWORK" || response === "ECONNREFUSED") {
            setFileDetetedFromServer(false);
            reject(new Error('Failed to destroy File, Backend is down!'));
          } else if (response === "C_ERR_NOT_FOUND" || response === "ERR_BAD_REQUEST") {
            setFileDetetedFromServer(true);
            reject('File was already deleted!');
          } else if (response === "ERR_INTERNAL_SERVER_ERROR") {
            setFileDetetedFromServer(false);
            reject(new Error('Failed to destroy File with code: 500'));
          } else if (response === "FILE_DELETED") {
            setFileDetetedFromServer(true);
            SetLoading(false);
            resolve('File deleted from Server!');
          } else {
            setFileDetetedFromServer(false);
            reject(new Error('Failed to destroy File with code: 500'));
          }
        });
        
        toast.promise(
          deleteFilePromise,
          {
            pending: 'Deleting File...',
            success: 'File deleted from Server!',
            error: {
              render({ data }) {
                if (!data.message) {
                  return 'File was already deleted!';
                }
                return data.message;
              },
            },
          }
        );
      }
    }, [_id, fileDetetedFromServer]);

    if (Loading) {
      return <LoadingSpinner loading_text={'Preparing File'}/>;
    }

    if (!o_encrypted_content || !key) {
      alert('File or Key is missing!');
      return (
        <div>
          <Typography variant="h3" style={{ color: '#3f51b5', marginBottom: '20px' }}>
            ERROR - File or Key is missing!
          </Typography>
        </div>
      );
    }

    const is_image = (file_extention) => {
      return file_extention === 'png' || file_extention === 'jpg' || file_extention === 'jpeg' || file_extention === 'gif';
    };

    const FileCard = ({file_name, file_size, file_type}) => {
      return (
        <Card sx={{ maxWidth: 300, m: 2, margin: '0 auto' }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <InsertDriveFile sx={{ fontSize: 48, color: 'secondary.main', mb: 2 }} />
          <Typography variant="h6" component="div" noWrap sx={{ width: '100%', textAlign: 'center' }}>
          {file_name}.{file_type}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Size: {generate_file_size_text(file_size)}
          </Typography>
          <IconButton aria-label="download" sx={{ mt: 2 }} onClick={download_base64_url_file}>
            <Download color='secondary.main'/>
          </IconButton>
        </CardContent>
        </Card>
      );
    }
  
    return (
      <div style={{ textAlign: 'center',padding: '20px' }}>
      {/* <Typography variant="h3" style={{ color: '#3f51b5', marginBottom: '20px' }}>
        Download Your File
      </Typography> */}

      {is_image(o_file_extention) ? (
        <div>
          <text value={_id + o_file_extention } />
          <img src={`data:image/png;base64,${DecryptedFile.split(',')[1]}`} alt="Decrypted File" style={{ maxWidth: '100%', height: 'auto', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }} />
        <Button variant="contained" color="secondary" onClick={download_base64_url_file} style={{ margin: '20px 0', padding: '10px 20px', fontSize: '16px' }}>
          Download .{o_file_extention} File 
        </Button>
        </div>
      ) : (	
        <div>
        <FileCard file_name={_id} file_size={DecryptedFile.length} file_type={o_file_extention} />
        </div>
      )}
      <Button variant="outlined" color={"secondary"} onClick={HandleReturn} style={{ margin: '20px 0', padding: '10px 20px', fontSize: '16px'}}>Return to Home</Button>
      </div>
    );
  };

  const DisplayViewNotificationPage = () => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
      setOpen(false);
      // navigate('/'); // Ensure navigation happens on close.
    };

    const handleYes = () => {
      SetLoading(true);
      SetFileOpen(true);
      handleClose();
    };

    if (!open) {
      navigate('/');
    }

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Do you want to open this File?</DialogTitle>
        <DialogContent style={{ color: "yellow", fontSize: "30px" }}>
          After viewing this File, it will be permanently destroyed.
        </DialogContent>
        <DialogContent style={{ color: "yellow",textDecoration: 'underline', fontSize: "30px" }}>
          There might be a delay when opening files above 10mb, please be patient.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="success">
            Yes
          </Button>
          <Button onClick={handleClose} color="error" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      {FileOpen ? 
          <DisplayFileDownloadPage encrypted_file={file} _file_extention={file_extention} _id={id} />	
        : 
          <DisplayViewNotificationPage />
      }
    </div>
  );
};

export default SelfDestructingFile;
